import { Action } from '@ngrx/store';
import {
  ApiAdminUserRequest,
  ApiAdminUserResponse,
  Credential,
  UnionCredential,
  UserCredential,
} from '../../models';
import { HttpErrorResponse } from '@angular/common/http';

export enum ActionTypes {
  LoadCredentials = '[Credentials] Load Credentials',
  LoadCredentialsSuccess = '[Credentials] Load Credentials Success',
  LoadCredentialsError = '[Credentials] Load Credentials Error',

  // Load Credential for deep links
  Load = '[Credentials] Load Credential',
  LoadSuccess = '[Credentials] Load Credential Success',
  LoadError = '[Credentials] Load Credential Error',

  // Create new User or Update existing user
  CreateUpdateUser = '[Credentials] Create or Update User',
  CreateUpdateUserError = '[Credentials] Create or Update User Error',
  CreateCredentialError = '[Credentials] Create Credential Error',

  // Create User Actions
  CreateApiUser = '[Credentials] Create API User',
  CreateApiUserSuccess = '[Credentials] Create API User Success',
  CreateApiUserError = '[Credentials] Create API User Error',

  DeleteUser = '[Credentials] Delete User',
  DeleteUserSuccess = '[Credentials] Delete User Success',
  DeleteUserError = '[Credentials] Delete User Error',

  CreateAdminApiUser = '[Credentials] Create Admin API User',
  CreateAdminApiUserSuccess = '[Credentials] Create Admin API User Success',
  CreateAdminApiUserError = '[Credentials] Create Admin API User Error',

  ToggleApiUserActive = '[Credentials] Toggle API User Active',
  ToggleApiUserActiveSuccess = '[Credentials] Toggle API User Active Success',
  ToggleApiUserActiveError = '[Credentials] Toggle API User Active Error',

  Update = '[Credentials] Update Credential',
  UpdateSuccess = '[Credentials] Update Credential Success',
  UpdateError = '[Credentials] Update Credential Error',

  // Action to remove Credential from Organization
  Remove = '[Credentials] Remove',
  RemoveSuccess = '[Credentials] Remove Success',
  RemoveError = '[Credentials] Remove Error',

  SendInviteEmail = '[Credentials] Send Invite Email',
  SendInviteEmailSuccess = '[Credentials] Send Invite Email Success',
  SendInviteEmailError = '[Credentials] Send Invite Email Error',

  SendAddToOrganizationEmail = '[Credentials] Send Added To Organization Email',
  SendAddToOrganizationEmailSuccess = '[Credentials] Send Added To Organization Email Success',
  SendAddToOrganizationEmailError = '[Credentials] Send Added To Organization Email Error',

  ForceLogout = '[Credentials] Force Logout',
  ForceLogoutSuccess = '[Credentials] Force Logout Success',
  ForceLogoutError = '[Credentials] Force Logout Error',

  Success = '[Credentials] Success',
  Error = '[Credentials] Error',

  RemoveAll = '[Credentials] Remove All',

  SetSelectedCredentialId = '[Credentials] Set Selected Credential',

  ClearState = '[Credentials] Clear State',
}

export class LoadCredentialsAction implements Action {
  readonly type = ActionTypes.LoadCredentials;
}
export class LoadCredentialsSuccessAction implements Action {
  readonly type = ActionTypes.LoadCredentialsSuccess;
  constructor(public payload: Array<Credential>) {}
}
export class LoadCredentialsErrorAction implements Action {
  readonly type = ActionTypes.LoadCredentialsError;
  constructor(public payload: string) {}
}

export class LoadAction implements Action {
  readonly type = ActionTypes.Load;
  constructor(public payload: string) {}
}
export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LoadSuccess;
  constructor(public payload: Credential) {}
}
export class LoadErrorAction implements Action {
  readonly type = ActionTypes.LoadError;
  constructor(public payload: any) {}
}

export class CreateUpdateUserAction implements Action {
  readonly type = ActionTypes.CreateUpdateUser;
  constructor(public payload: UserCredential) {}
}
export class CreateUpdateUserErrorAction implements Action {
  readonly type = ActionTypes.CreateUpdateUserError;
  constructor(public payload: any) {}
}

export class CreateCredentialErrorAction implements Action {
  readonly type = ActionTypes.CreateCredentialError;
  constructor(public payload: any) {}
}
export class CreateApiUserAction implements Action {
  readonly type = ActionTypes.CreateApiUser;
  constructor(public payload: UserCredential) {}
}
export class CreateApiUserSuccessAction implements Action {
  readonly type = ActionTypes.CreateApiUserSuccess;
  constructor(public payload: any) {}
}
export class CreateApiUserErrorAction implements Action {
  readonly type = ActionTypes.CreateApiUserError;
  constructor(public payload?: any) {}
}

export class DeleteUserAction implements Action {
  readonly type = ActionTypes.DeleteUser;
  constructor(public payload: string) {} // uid
}
export class DeleteUserSuccessAction implements Action {
  readonly type = ActionTypes.DeleteUserSuccess;
}
export class DeleteUserErrorAction implements Action {
  readonly type = ActionTypes.DeleteUserError;
  constructor(public payload: string) {}
}

export class CreateAdminApiUserAction implements Action {
  readonly type = ActionTypes.CreateAdminApiUser;
  constructor(public payload: ApiAdminUserRequest) {}
}
export class CreateAdminApiUserSuccessAction implements Action {
  readonly type = ActionTypes.CreateAdminApiUserSuccess;
  constructor(public payload: ApiAdminUserResponse) {}
}
export class CreateAdminApiUserErrorAction implements Action {
  readonly type = ActionTypes.CreateAdminApiUserError;
  constructor(public payload: HttpErrorResponse) {}
}

export class ToggleApiUserActiveAction implements Action {
  readonly type = ActionTypes.ToggleApiUserActive;
  constructor(public payload: Credential) {}
}
export class ToggleApiUserActiveSuccessAction implements Action {
  readonly type = ActionTypes.ToggleApiUserActiveSuccess;
}
export class ToggleApiUserActiveErrorAction implements Action {
  readonly type = ActionTypes.ToggleApiUserActiveError;
  constructor(public payload?: any) {}
}

export class UpdateAction implements Action {
  readonly type = ActionTypes.Update;
  constructor(public payload: { cred: Credential; sendEmailAlert: boolean }) {}
}
export class UpdateSuccessAction implements Action {
  readonly type = ActionTypes.UpdateSuccess;
  constructor(public payload: { cred: Credential; sendEmailAlert: boolean }) {}
}
export class UpdateErrorAction implements Action {
  readonly type = ActionTypes.UpdateError;
  constructor(public payload: any) {}
}

export class RemoveAction implements Action {
  readonly type = ActionTypes.Remove;
  constructor(public payload: Credential) {}
}
export class RemoveSuccessAction implements Action {
  readonly type = ActionTypes.RemoveSuccess;
}
export class RemoveErrorAction implements Action {
  readonly type = ActionTypes.RemoveError;
  constructor(public payload: any) {}
}

export class SendInviteEmailAction implements Action {
  readonly type = ActionTypes.SendInviteEmail;
  constructor(public payload: UserCredential | ApiAdminUserResponse) {}
}

export class SendInviteEmailSuccessAction implements Action {
  readonly type = ActionTypes.SendInviteEmailSuccess;
  // constructor(public payload: any) {}
}
export class SendInviteEmailErrorAction implements Action {
  readonly type = ActionTypes.SendInviteEmailError;
  constructor(public payload?: any) {}
}
export class SendAddToOrganizationEmailAction implements Action {
  readonly type = ActionTypes.SendAddToOrganizationEmail;
  constructor(public payload: any) {}
}

export class SendAddToOrganizationEmailSuccessAction implements Action {
  readonly type = ActionTypes.SendInviteEmailSuccess;
  // constructor(public payload: any) {}
}
export class SendAddToOrganizationEmailErrorAction implements Action {
  readonly type = ActionTypes.SendAddToOrganizationEmailError;
  constructor(public payload?: any) {}
}

export class SuccessAction implements Action {
  readonly type = ActionTypes.Success;
}

export class ErrorAction implements Action {
  readonly type = ActionTypes.Error;
  constructor(public payload?: any) {}
}

export class RemoveAllAction implements Action {
  readonly type = ActionTypes.RemoveAll;
}

export class SetSelectedCredentialIdAction implements Action {
  readonly type = ActionTypes.SetSelectedCredentialId;
  constructor(public payload: string) {}
}

export class ForceLogoutAction implements Action {
  readonly type = ActionTypes.ForceLogout;
  constructor(public payload: string) {} // uid
}

export class ForceLogoutSuccessAction implements Action {
  readonly type = ActionTypes.ForceLogoutSuccess;
}

export class ForceLogoutErrorAction implements Action {
  readonly type = ActionTypes.ForceLogoutError;
  constructor(public payload: any) {}
}

export class ClearStateAction implements Action {
  readonly type = ActionTypes.ClearState;
}

export type CredentialsActionsUnion =
  | LoadCredentialsAction
  | LoadCredentialsSuccessAction
  | LoadCredentialsErrorAction
  | LoadAction
  | LoadSuccessAction
  | LoadErrorAction
  | CreateUpdateUserAction
  | CreateUpdateUserErrorAction
  | CreateCredentialErrorAction
  | CreateApiUserAction
  | CreateApiUserSuccessAction
  | CreateApiUserErrorAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserErrorAction
  | CreateAdminApiUserAction
  | CreateAdminApiUserSuccessAction
  | CreateAdminApiUserErrorAction
  | ToggleApiUserActiveAction
  | ToggleApiUserActiveSuccessAction
  | ToggleApiUserActiveErrorAction
  | UpdateAction
  | UpdateSuccessAction
  | UpdateErrorAction
  | RemoveAction
  | RemoveSuccessAction
  | RemoveErrorAction
  | SendInviteEmailAction
  | SendInviteEmailSuccessAction
  | SendInviteEmailErrorAction
  | SendAddToOrganizationEmailAction
  | SendAddToOrganizationEmailSuccessAction
  | SendAddToOrganizationEmailErrorAction
  | SuccessAction
  | ErrorAction
  | RemoveAllAction
  | ForceLogoutAction
  | ForceLogoutSuccessAction
  | ForceLogoutErrorAction
  | SetSelectedCredentialIdAction
  | ClearStateAction;
